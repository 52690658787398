import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Lead} from '../shared/models/lead';
import {AppStateService} from '../shared/services/app-state.service';
import {LeadService} from '../shared/services/lead.service';
import {CookieService} from 'ngx-cookie-service';
// @ts-ignore
import eProviders from '../../assets/utilities.json';
import eMalls from '../../assets/malls.json';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
  options: string[] = eProviders;
  malls: string[] = eMalls;
  firstNameInValid = false;
  lstNameInValid = false;
  electricityProviderInValid = false;
  emailInvalid = false;
  phoneNumberInvalid = false;
  addressInvalid = false;
  zipCodeInvalid = false;
  displayForm = false;
  jornaya: string;
  stateInvalid = false;
  @ViewChild('formView', {static: false}) MyProp: ElementRef;

  nameRegex = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
  phoneRegex = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
  // tslint:disable-next-line:max-line-length
  emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  streetRegex = new RegExp(/^(\d{3,})\s?(\w{0,5})\s([a-zA-Z]{2,30})\s([a-zA-Z]{2,15})\.?\s?(\w{0,5})$/);
  lead = new Lead();

  constructor(private _app: AppStateService, private _lead: LeadService, private _cookieService: CookieService) {
    this.lead = this._app.lead;
    this.lead.leadData.monthlyBill = '$0 - $50';
    this.lead.leadData.shade = 'A little bit';
    this.lead.leadData.creditGrade = 'Good';
  }

  ngOnInit() {
    this.jornaya = this._cookieService.get('leadid_token-B04E3B3A-5ADD-35D0-8BCD-15234DDCEBBC-082F85CD-ACF8-08C2-5D72-6AEBFDBBB27B');
  }

  validateZipCode() {
    if (this.lead.geoLocation.zipcode.length === 5) {
      this.zipCodeInvalid = false;
    } else {
      this.zipCodeInvalid = true;
    }
  }

  validateAddress() {
    if (this.lead.leadData.address.length >= 5) {
      this.addressInvalid = false;
    } else {
      this.addressInvalid = true;
    }
  }

  validateState() {
    this.stateInvalid = this.lead.geoLocation.zipcode.length < 0;
  }

  validatePhoneNumber() {
    if (this.lead.leadData.phoneNumber.length >= 7) {
      if (this.phoneRegex.test(this.lead.leadData.phoneNumber)) {
        this.phoneNumberInvalid = false;
      } else {
        this.phoneNumberInvalid = true;
      }
    } else {
      this.phoneNumberInvalid = true;
    }
  }

  validateElectricityProvider() {
    if (this.lead.leadData.provider.length >= 3) {
      this.electricityProviderInValid = false;
    } else {
      this.electricityProviderInValid = true;
    }
  }

  validateFirstName() {
    if (this.lead.leadData.firstName.length >= 3) {
      if (this.nameRegex.test(this.lead.leadData.firstName)) {
        this.firstNameInValid = false;
      } else {
        this.firstNameInValid = true;
      }
    } else {
      this.firstNameInValid = true;
    }
  }

  validateLastName() {
    if (this.lead.leadData.lastName.length >= 3) {
      if (this.nameRegex.test(this.lead.leadData.lastName)) {
        this.lstNameInValid = false;
      } else {
        this.lstNameInValid = true;
      }
    } else {
      this.lstNameInValid = true;
    }
  }

  validateEmailAdddress() {
    if (this.lead.leadData.email.length >= 5) {
      if (this.emailRegex.test(this.lead.leadData.email)) {
        this.emailInvalid = false;
      } else {
        this.emailInvalid = true;
      }
    } else {
      this.emailInvalid = true;
    }
  }

  showForm() {
    this.displayForm = !this.displayForm;
    setTimeout(() => {
      this.delayDisplayForm();
    }, 500);
  }

  delayDisplayForm() {
    window.scrollTo(0, (document.body.scrollHeight - 250));
  }

  validateForm() {
    this.validateLastName();
    this.validateFirstName();
    this.validatePhoneNumber();
    this.validateZipCode();
    if (
      this.lead.leadData.firstName.length > 0
      && this.lead.leadData.lastName.length > 0
      && this.lead.leadData.phoneNumber.length > 0
      && this.lead.geoLocation.zipcode.length > 0
      && !this.firstNameInValid
      && !this.lstNameInValid
      && !this.emailInvalid
      // && !this.addressInvalid
      && !this.phoneNumberInvalid
      && !this.zipCodeInvalid
    ) {
      this.lead.leadContext.jornayaToken = this.jornaya;
      this._app.lead = this.lead;
      // console.log('lead sent');
      this._lead.saveLead();
    }
  }
}
