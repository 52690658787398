import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppStateService} from './app-state.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  saveDataUrl = environment.leadEndPoint + 'api/EmailIngestion';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient, private _appState: AppStateService, private _router: Router) {
  }

  saveLead() {
    this._appState.lead.leadContext.date = (new Date(Date.now())).toDateString();
    this.saveLeadToService().subscribe(
      (data) => {
      },
      (error) => console.log(error),
      () => {
        this._appState.loading.next(false);
        this._router.navigate(['/thank-you'], {queryParams: {zip_code: this._appState.lead.geoLocation.zipcode}});
      },
    );
  }

  private saveLeadToService() {
    try {
      return this._http.post(
        this.saveDataUrl,
        JSON.stringify(this._appState.lead),
        this.httpOptions
      );
    } catch (e) {
      console.log(e);
    }
  }
}
