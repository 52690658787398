class UserContext {
  userAgent: string;
  ip_address: string;
  query_string: string;
  session_id: string;
}

class LeadContext {
  vertical = 'solar';
  experiment_id: string;
  jornayaToken: string;
  campaign: string;
  token: string;
  date: string;
  FromSite = 'www.homesaver.solar';
}

class ClickContext {
  subId: string;
  channel: string;
  src: string;
  ad_id: string;
  ad_position: string;
  referrer: string;
  utm_src: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
  utm_campaign: string;
  click_id: string;
  affiliate_id: string;
  affiliate_source_id: string;
  affiliate_sub_id: string;
  gclid: string;
  prepulated: string;
}

class TrackingParam {
  name: string;
}

class LeadData {
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  address = '';
  monthlyBill = '';
  shade = '';
  provider = '';
  creditGrade = '';
  location = '';
}

class GeoLocation {
  zipcode = '';
  county: string;
  city: string;
  state: string;
  country = 'USA';
  address: string;
}

class CampaignDetails {
  campaignId = '';
  campaignKey = '';
  campaignTest = '';
}

export class Lead {
  userContext: UserContext = new UserContext();
  leadContext: LeadContext = new LeadContext();
  clickContext: ClickContext = new ClickContext();
  leadData: LeadData = new LeadData();
  geoLocation: GeoLocation = new GeoLocation();
  campaginDetails: CampaignDetails = new CampaignDetails();
}

