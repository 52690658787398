import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import {AppStateService} from './shared/services/app-state.service';
import {SessionService} from './shared/services/session.service';
import {LeadService} from './shared/services/lead.service';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import {CookieService} from 'ngx-cookie-service';
import {MaterialModule} from './material/material.module';
import { CpccComponent } from './cpcc/cpcc.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ThankYouComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyComponent,
    TermsUseComponent,
    CpccComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [LeadService, SessionService, AppStateService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {}
