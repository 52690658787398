import {Component, OnInit} from '@angular/core';
import {Lead} from './shared/models/lead';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {AppStateService} from './shared/services/app-state.service';
import {SessionService} from './shared/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'www.solarsaver.solar';
  lead = new Lead();

  constructor(
    private _session: SessionService,
    private _appState: AppStateService,
    private _route: ActivatedRoute,
    private _http: HttpClient
  ) {
    this.getClientIp().subscribe(
      response => {
        this._appState.clientIp.next(JSON.stringify(response));
        this.lead.userContext.ip_address = JSON.parse(JSON.stringify(response)).ip;
      }
    );
    this._session.getUserSession().subscribe(
      (sessionNumber) => {
        const sessionId = JSON.parse(JSON.stringify(sessionNumber));
        this._appState.sessionId.next(sessionId['sessionId']);
        this.lead.userContext.session_id = JSON.parse(JSON.stringify(sessionNumber));
      }
    );
    this._route.queryParams.subscribe(params => {
      this.lead.userContext.query_string = JSON.parse(JSON.stringify(params));
    });
    this._appState.loadingText.next('Validating Zip Code');
    this.lead.clickContext.gclid = this._route.snapshot.queryParamMap.get('gclid') || '';
    this.lead.clickContext.subId = this._route.snapshot.queryParamMap.get('subId') || '';
    this.lead.clickContext.channel = this._route.snapshot.queryParamMap.get('channel') || '';
    this.lead.clickContext.src = this._route.snapshot.queryParamMap.get('src') || '';
    this.lead.clickContext.ad_id = this._route.snapshot.queryParamMap.get('adid') || '';
    this.lead.clickContext.ad_position = this._route.snapshot.queryParamMap.get('ad_position') || '';
    this.lead.clickContext.utm_src = this._route.snapshot.queryParamMap.get('utm_src') || '';
    this.lead.clickContext.utm_medium = this._route.snapshot.queryParamMap.get('utm_medium') || '';
    this.lead.clickContext.utm_term = this._route.snapshot.queryParamMap.get('utm_term') || '';
    this.lead.clickContext.utm_content = this._route.snapshot.queryParamMap.get('utm_content') || '';
    this.lead.clickContext.utm_campaign = this._route.snapshot.queryParamMap.get('utm_campaign') || '';
    this.lead.clickContext.click_id = this._route.snapshot.queryParamMap.get('click_id') || '';
    this.lead.clickContext.affiliate_id = this._route.snapshot.queryParamMap.get('affiliate_id') || '';
    this.lead.clickContext.affiliate_source_id = this._route.snapshot.queryParamMap.get('affiliate_source_id') || '';
    this.lead.clickContext.affiliate_sub_id = this._route.snapshot.queryParamMap.get('affiliate_sub_id') || '';
    this.lead.leadContext.vertical = 'solar';
    this.lead.leadContext.experiment_id = this._route.snapshot.queryParamMap.get('experiment_id') || '';
    this.lead.leadContext.campaign = this._route.snapshot.queryParamMap.get('campaign') || '';
    this.lead.leadContext.token = this._route.snapshot.queryParamMap.get('token') || '';
    this._appState.lead = this.lead;
  }

  private getClientIp() {
    const ipAddress = this._http.get('https://api.ipify.org/?format=json');
    return ipAddress;
  }

  ngOnInit(): void {
    this._appState.lead = this.lead;
  }
}
