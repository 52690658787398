import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ThankYouComponent} from './thank-you/thank-you.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsUseComponent} from './terms-use/terms-use.component';
import {CpccComponent} from "./cpcc/cpcc.component";

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'ccpc', component: CpccComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms-use', component: TermsUseComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
